/*******Media Queries *******/
@media (max-width: 69rem) {
  .form-area input,
  .form-area select,
  .icon-label {
    width: 60%;
  }
}
@media (max-width: 56rem) {
  .form-area input,
  .form-area select,
  .icon-label {
    width: 80%;
  }
  .incident-flex {
    gap: 4.2rem;
  }
  .container,
  .footer {
    padding: 0 4.2rem;
  }
}
@media (max-width: 44rem) {
  .form-area input,
  .form-area select,
  .icon-label {
    width: 100%;
  }
}
@media (max-width: 37.5rem) {
  .incident-flex {
    grid-template-columns: 1fr;
  }
  .rpa-info {
    grid-row: 2;
  }

  /* General */
  .heading-secondary {
    font-size: 2.6rem;
  }

  /* Incident Form */
  .icon-label {
    font-size: 1.8rem;
  }
  .form-area input,
  .form-area select {
    font-size: 1.6rem;
  }
}
@media (max-width: 35rem) {
  .container,
  .footer {
    padding: 0 2.4rem;
  }
  .footer {
    justify-content: center;
  }
}
