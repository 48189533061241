.logo-img {
  width: 55%;
}
.main-nav ul {
  list-style-type: none;
}
/* Section Header */
.header {
  display: flex;
  /* display: none; */
  justify-content: space-between;
  height: 9.6rem;
  align-items: center;
  padding: 0 4.8rem;
  margin: 1.2rem 0;
}

/* Navbar */
.main-nav-list {
  display: flex;
  align-items: center;
  gap: 3.2rem;
}

/* .main-nav-link:link,
.main-nav-link:visited,
.main-nav-link {
  text-decoration: none;
  color: #333333;
  font-size: 2rem;
  position: relative;
} */
.temp-logo {
  font-size: 4.8rem;
}
.signIn-Button {
  color: #fff !important;
  border-radius: 500px;
  padding: 0.8rem 2.4rem;
  background-color: #0066cb;
}
.nav-dropdown {
  display: none;
  font-size: 2rem;
  padding: 1.2rem 2.4rem;
  background-color: #eee;
  position: absolute;
  top: 20px;
  left: 0;
  border-radius: 9px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  /* z-index: 2; */
}
/* .nav-dropdown:hover {
  display: inline-block;
} */
/* .main-nav-link:hover + .nav-dropdown {
  display: block;
} */
/* .sub-link {
  color: #0066cb !important;
} */

/* Test1 */
/* MOBILE */
.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;

  display: none;
}

.icon-mobile-nav {
  height: 4rem;
  width: 4rem;
  color: #333;
}

.icon-mobile-nav[name='close-outline'] {
  display: none;
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
  /* MOBILE NAVIGATION */
  .btn-mobile-nav {
    display: block;
    z-index: 9999;
  }
  /* .main-nav-link:link,
  .main-nav-link:visited {
    text-decoration: none;
    color: #333333;
    font-size: 2rem;
  } */

  .main-nav {
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
    position: absolute;
    width: 0;
    top: 0;
    right: 0;
    height: 100vh;
    /* transform: translateX(-100%); */
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;

    /* Hide navigation */
    /* Allows NO transitions at all */
    /* display: none; */

    /* 1) Hide it visually */
    opacity: 0;

    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;

    /* 3) Hide it from screen readers */
    visibility: hidden;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    /* transform: translateX(0); */
    width: 100%;
    height: 100vh;
    z-index: 1;
  }

  .nav-open .icon-mobile-nav[name='close-outline'] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name='menu-outline'] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }

  /* .temp-logo img {
    width: 35%;
  } */
  .logo-img {
    width: 52%;
  }
  .header {
    padding: 0 2.4rem;
  }
}

/* Ḍropdowntest */
.dropbtn,
.dropbtn a {
  text-decoration: none;
  color: #333333;
  font-size: 2.1rem;
  font-weight: 700;
  border: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 9px;
}

.dropdown-content a {
  color: #333333;
  padding: 12px 16px;
  /* padding-right: 30px; */
  text-decoration: none;
  display: block;
  font-size: 1.8rem;
}

.dropdown-content a:hover {
  background-color: #ddd;
}
.dropdown-content a:hover:first-child {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
.dropdown-content a:hover:last-child {
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* .dropdown:hover .dropbtn {
  background-color: #3e8e41;
} */
