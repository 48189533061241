/*
--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

- Line heights
Default: 
Small: 
Medium: 
Paragraph default: 
Large: 

- Letter spacing

--- 02 COLORS
- Primary: 
- Tints: 
- Shades: 
- Accents:
- Greys


--- 05 SHADOWS

0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);

--- 06 BORDER-RADIUS

Default: 9px
Medium: 11px

--- 07 WHITESPACE

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

/* GLOBAL ELEMENTS */
html {
  font-size: 62.5%;
  /* font-size: 100%; */
  overflow-x: hidden !important;
  font-family: 'Open Sans', sans-serif;
  /* Does NOT work on Safari */
  scroll-behavior: smooth;
  /* background-image: linear-gradient(to right, #fdf9f4, #f2e8df); */
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  /* background-image: url('../images/bg.PNG'); */
  background-size: cover;
  overflow-x: hidden !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  height: 100%;
  width: 100%;
}

/* Disable input type number arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/* REUSABLE ELEMENTS */
.center {
  margin: 0 auto;
}
.text-center {
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  justify-content: center;
}
.flex-end {
  justify-content: flex-end;
}
.mb-1 {
  margin-bottom: 1.2rem !important;
}
.mb-2 {
  margin-bottom: 2.4rem !important;
}
.mb-3 {
  margin-bottom: 3.2rem !important;
}
.mb-4 {
  margin-bottom: 4.4rem !important;
}
.mb-5 {
  margin-bottom: 5.5rem !important;
}
.mt-1 {
  margin-top: 1.2rem !important;
}
.mt-2 {
  margin-top: 2.4rem !important;
}
.mt-3 {
  margin-top: 3.2rem !important;
}
.mt-4 {
  margin-top: 4.4rem !important;
}
.mt-5 {
  margin-top: 5.5rem !important;
}

.container {
  /* 1140px */
  /* max-width: 120rem; */
  padding: 0 6.4rem;
  margin: 0 auto;
}
.heading-secondary {
  color: #003366;
  font-size: 3rem;
}
select,
input {
  color: #666;
}
option {
  color: #000;
}
::-webkit-calendar-picker-indicator {
  background-image: url('../images/icons/icon9.svg');
}
.min-height {
  min-height: 70vh;
}
.pos-rel {
  position: relative;
}

/* Alert */
.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: #dc3545;
  color: #f1f1f1f1;
  /* color: red; */
  /* position: -webkit-sticky; */
  /* top: 0;
  left: 50%; */
  font-size: 1.4rem;
  border-radius: 25px;
  transition: all 1.5s;
  text-align: center;
}
