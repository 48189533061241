.subHeading {
  font-size: 15px;
  margin-bottom: 3px;
  margin-left: 30px;
  display: list-item !important;
}
.subHeadingContainer {
  margin-bottom: 20px;
  background-color: #e0e0e0;
  width: 50%;
  padding: 10px 15px;
  border-radius: 10px;
}
.titleConatiner {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}
.title {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}
@media (max-width: 37.5rem) {
  .subHeading {
    display: block;
    font-size: 13px;
    margin-bottom: 3px;
  }
}
@media (max-width: 69rem) {
  .subHeadingContainer {
    width: 100%;
  }
}
